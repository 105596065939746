/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/ryan-profile.jpg/" }) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

  const { author } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `flex`,
        marginBottom: rhythm(1.0),
      }}
    >
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          minWidth: 100,
          borderRadius: `50%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <p>
        Written by <strong>{author}</strong> who lives and works in Colorado. <i>WARNING:</i> all posts are thoughts in progress and subject to change at any time without notice. Enjoy.
        {` `}
        <br />
        <b>check out my...</b>&nbsp;
        <a href="https://stackoverflow.com/users/988683/rncrtr">Stack Overflow</a> |&nbsp;
        <a href="https://www.quora.com/profile/Ryan-Carter-38">Quora Answers</a> |&nbsp;
        <b>CSS Library:</b> <a href="https://github.com/rncrtr/diet-bulma">Diet Bulma</a> |&nbsp;
        <b>Old IE Inspector:</b> <a href="https://github.com/rncrtr/antiquated">Antiquated</a>
      </p>
    </div>
  )
}

export default Bio

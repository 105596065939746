import React from "react"
import { Link } from "gatsby"
import '../pages/styles.scss'
import { rhythm, scale } from "../utils/typography"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <h1
          className="has-text-primary has-text-centered"
          style={{
            ...scale(1.5),
            marginBottom: rhythm(1.5),
            marginTop: 0,
            fontWeight: "bold"
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <h3
          className="has-text-primary"
          style={{
            ...scale(0.5),
            marginBottom: rhythm(1.5),
            marginTop: 0,
            fontWeight: "bold"
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      )
    }
    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(36),
          padding: `${rhythm(0.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>{header}</header>
        <main style={{minHeight: rhythm(24)}}>{children}</main>
        <footer className="">
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
          <span className="is-pulled-right">
            personal:<br />
            <a href="/about">about</a> |&nbsp;
            <b>twitter:</b> <a href="https://twitter.com/rncrtr">@rncrtr</a> |&nbsp;
            <a href="https://www.amazon.com/Jeenome-Dark-White-Day-Tales-ebook/dp/B01N5LFVU0/ref=sr_1_1?keywords=jeenome+and+the+dark&qid=1578343201&sr=8-1">short story</a>
            </span>
        </footer>
      </div>
    )
  }
}

export default Layout
